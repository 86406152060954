import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Note: You can change "images" to whatever you'd like.

const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
            nodes {
              id
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
              )
            }
          }
      }
    `}
    render={data => {
      const image = data.allImageSharp.nodes.find(n=>n.gatsbyImageData.images.fallback.src.includes(props.src))
 
      if (!image) { 
        return null;
      }
      //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      return <GatsbyImage className={`${props.rounded?'rounded-xl':''} ${props.className ? props.className : ''}`} alt={props.alt} image={getImage(image)} style={props.width ? {width: props.width, height: props.width} : {}}/>;
    }}
  />
);

export default Image;