import React from 'react'

export default function Footer() {
    return (
        <div className='flex justify-center w-100 py-5 text-white bg-primary'>
            All rights reserved. Dentsu Marketing Cloud 2022 
            &nbsp; | &nbsp;
            <a href="https://dentsumarketing.cloud/terms-of-service/" target='_blank' rel="noreferrer noopener">Terms</a>
            &nbsp; |  &nbsp;
            <a href="https://dentsumarketing.cloud/privacy-policy/" target='_blank' rel="noreferrer noopener">Privacy</a>
        </div>
    )
}
