import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

export default function Layout({ title, children }) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content="Welcome to dentsu marketing cloud" />
      </Helmet>
      <Header />
        {children}
      <Footer />
    </>
  );
}
