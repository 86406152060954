import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "../components/Button";

export default function Navbar() {
  return (
    <div className="sticky z-10 top-0 w-100 py-3 bg-white/60 backdrop-blur-xl border-b">
      <div className="w-11/12 md:w-3/4 mx-auto flex items-center justify-between">
        <a href='/'>
        <StaticImage
          src="../images/logologo.png"
          alt="DMC"
          placeholder="tracedSVG"
          layout="fixed"
          width={100}
        />
        </a>
        <div className="hidden md:flex items-center justify-between w-1/2 lg:w-1/3">
          <a href="/about">About</a>
          <a href="//blog.dentsumarketing.cloud">Blog</a>
          <a href="//blog.dentsumarketing.cloud/contact">Contact</a>
          <a href="//dentsumarketing.cloud/dmcinsights/">Research</a>
          <a href="//blog.dentsumarketing.cloud/ddl-in-news/">News</a>
        </div>
        <a href="mailto:dmcsupport@dentsu.com?subject=Demo%20Request&body=Hi,%20I%20would%20like%20to%20request%20a%20demo%20for%20DMC."><Button type='primary'>Request a demo</Button></a>
      </div>
    </div>
  );
}
